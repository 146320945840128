import React from 'react'
import Test1 from '../../images/jason-crickmer.jpeg'
import Test2 from '../../images/gerardo-gada.jpeg'
import Test3 from '../../images/jay-watson.jpeg'
import Test4 from '../../images/mik-barclay.jpeg'
import Test5 from '../../images/trina-pirtle.jpeg'
import Test6 from '../../images/steve-semelsberger.jpeg'
import Test7 from '../../images/fred-smith.jpeg'
import Test8 from '../../images/kent-white.jpeg'
import Test9 from '../../images/glen-ford.jpeg'
import Test10 from '../../images/sal-sanchez.jpeg'
import Test11 from '../../images/andrew-glover.jpg'









class AllTestimonials extends React.Component {
  
  render(){
    return(
      <div className="testimonials-section text-center">
        <div className="container">
          <div className="row">

            <div className="col-md-6 my-5">
              <div className="testimonial-item"><img src={ Test1 } alt="testimonial-1"/>
                <div className="testimonial-text">
                  <p>As my partner running a SaaS business unit, I could not have asked for a smarter and more dedicated sales leader than Joe. To each customer and prospect relationship, Joe brought organizational focus, passion for win-win outcomes, creative problem-solving, and a selfless and humble perspective. Joe organized, built, and managed a team of marketers, sales professionals, and business development professionals - and he helped them achieve remarkable results in a difficult environment. I highly recommend Joe as an accomplished sales executive and creative organizational leader. </p>
                  <div className="testi-rank">
                    <h4>Jason Crickmer</h4>
                    <span className="testimonial-company">Delivery and Customer Success Leader</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 my-5">
              <div className="testimonial-item"><img src={ Test2 } alt="testimonial-2"/>
                <div className="testimonial-text">
                  <p>Joe is a fantastic sales leader. His team trust him, he understands customer needs, and he understands what is needed to close sales.  Joe is an asset to any B2B sales team. I enjoyed working with Joe and would enjoy an opportunity to work together again.</p>
                  <div className="testi-rank">
                    <h4>Gerardo A Dada</h4>
                    <span className="testimonial-company">Chief Marketing Officer, <em>DataCore Software</em></span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 my-5">
              <div className="testimonial-item"><img src={ Test3 } alt="testimonial-3"/>
                <div className="testimonial-text">
                  <p>Joe's creative yet focused sales leadership backed by financial modeling enabled me to realize the importance of ROI in selling. His ability to listen and rally the teams to develop a winning solution is unmatched. I would highly recommend Joe for a leadership role where you need a leader to embrace ambiguity and drive sales while providing insight into new offerings that stem from listening to customers.</p>
                  <div className="testi-rank">
                    <h4>Jay Watson</h4>
                    <span className="testimonial-company">Business Development, <em>Dell EMC</em></span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 my-5">
              <div className="testimonial-item"><img src={ Test4 } alt="testimonial-4"/>
                <div className="testimonial-text">
                  <p>Joe is creative, detail conscious and clear thinking strategic leader. He has a vision for where he wants the business to go and sets about making it a reality by sharing his goal, understanding and leveraging the necessary resources, then working alongside his team to deliver the plan. Joe is always ready to give and take constructive feedback to create a culture of respect where each individual can feel valued. </p>
                  <div className="testi-rank">
                    <h4>Mike Barclay</h4>
                    <span className="testimonial-company">VP|Senior Sales Leader|Sales Director|Enterprise & Mid Market Expert, <em>(August 7, 2014, Mike reported directly to Joe)</em></span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 my-5">
              <div className="testimonial-item"><img src={ Test5 } alt="testimonial-5"/>
                <div className="testimonial-text">
                  <p>Joe brought skilled operational and sales experience, helping navigate the company through complex challenges and demonstrating laudable leadership under pressure. He is adept in his approach in guiding prospects and customers to understand how to move past their business challenges with solutions that provide tangible value. Joe is a strategic thinker, achieving short-term wins while never losing sight of the long-term approach to selling and gaining business over time. He deftly navigates the sales cycle and is an expert at building lasting relationships that promote partnership and success. Joe is a thoughtful manager and is always looking for ways to help his team sell more, faster.</p>
                  <div className="testi-rank">
                    <h4>Trina Pirtle</h4>
                    <span className="testimonial-company">Vice President Customer Success, <em>First Advantage</em></span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 my-5">
              <div className="testimonial-item"><img src={ Test6 } alt="testimonial-6"/>
                <div className="testimonial-text">
                  <p>I recruited and hired Joe to drive revenue for Pluck. Thoughtful insightful and consultative, Joe has a keen knack for how businesses and people work. He draws on his myriad of fascinating experiences to truly partner with executives, clients and prospects adding value with each interaction. One of the good guys!</p>
                  <div className="testi-rank">
                    <h4>Steve Semelsberger</h4>
                    <span className="testimonial-company">CEO, <em>Testlio</em></span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 my-5">
              <div className="testimonial-item"><img src={ Test7 } alt="testimonial-7"/>
                <div className="testimonial-text">
                  <p>Joe is a leader that truly cares about his teams and co-workers and strives to be the consummate deal coach. A strategic thinker with ability to focus on the details, Joe also works at building strong cross-functional relationships to foster teamwork and productivity. Joe can look at any situation and put into play creative solutions to drive results and has no problem seeing the big picture while maintaining a focus on the minutia.</p>
                  <div className="testi-rank">
                    <h4>Fred Smith</h4>
                    <span className="testimonial-company">Senior Vice President Of Sales, <em>Sourceday.com</em></span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 my-5">
              <div className="testimonial-item"><img src={ Test8 } alt="testimonial-8"/>
                <div className="testimonial-text">
                  <p>Joe has a powerful combination of strategic vision and highly effective execution skills that makes him an invaluable part of a great team. He's always kept a laser focus on results and delivers them, while building strong relationships on his team, with clients, and across the entire organization. He listens, and has a gift for turning great ideas into actionable, innovative strategies that get people behind them with measurable results. His ability to lead, coach, and think creatively made him one of the best managers I've ever worked with, and makes him go-to leader in sales, strategic planning, and creative problem-solving. Anyone would be very fortunate to have Joe on their team.</p>
                  <div className="testi-rank">
                    <h4>Kent White</h4>
                    <span className="testimonial-company">Product Manager, Services, <em>Q2ebanking</em></span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 my-5">
              <div className="testimonial-item"><img src={ Test9 } alt="glen-ford"/>
                <div className="testimonial-text">
                  <p>I had the pleasure of working with Joe for a couple of years at Demand Media. As a PM, I found his insight and input extremely valuable. He brought a wide range of experience in sales, biz dev, and marketing to the role, and his well-considered opinions and ideas had a great positive impact on the growth of our product, and in this he goes above and beyond the already-valuable task of leading sales strategy. Plus, he's calm and collected and a joy to work with! </p>
                  <div className="testi-rank">
                    <h4>Glen Ford</h4>
                    <span className="testimonial-company">Product Manager, <em>Alegion</em></span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 my-5">
              <div className="testimonial-item"><img src={ Test10 } alt="sal-sanchez"/>
                <div className="testimonial-text">
                  <p>Joe is a creative results-oriented sales manager who was a pleasure to work with. He provided valuable guidance for complex sales cycles with prospects & clients in an industry renowned for extreme due diligence. The challenges this created didn't stop our team from significantly increasing our client base in financial services & healthcare. Joe's contribution to this new business being won was vital!</p>
                  <div className="testi-rank">
                    <h4>Sal Sanchez</h4>
                    <span className="testimonial-company">Enterprise Account Executive</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 my-5">
              <div className="testimonial-item"><img src={ Test11 } alt="andrew-glover"/>
                <div className="testimonial-text">
                  <p>Joe "started Dell Financial Services"- Great leader and great at building relationships. Had a vision for DFS and executed well to the plan. A high achiever- very driven.</p>
                  <div className="testi-rank">
                    <h4>Andrew Glover</h4>
                    <span className="testimonial-company">Director</span>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    )
  }
}

export default AllTestimonials;