import React from 'react'
import Layout from '../layouts'
import Write from '../images/write.jpg'
import AllTestimonials from '../components/testimonial/alltestimonial.js'




const Testimonial = () => (
  <Layout head={{ title: 'Testimonials' }}>
  	<div className="alltestimonial">
  		<div className="testimonial-page">
			  <div id="hero-section" className="bg-cover" style={{background:`url("${Write}")`}}>
			    <div className="container">
			      <div className="slim-content">
			        <h1 className="text-red">What Our Clients Say</h1>
			      </div>
			    </div>
			  </div>
			  <AllTestimonials />
			</div>
  	</div>
	</Layout>

)

export default Testimonial